let script = document.createElement('script')
script.async = true
script.src = "https://www.googletagmanager.com/gtag/js?id=AW-10868599597"
script.onload = function () {
  window.dataLayer = window.dataLayer || []
  function gtag() {
    console.log(`google trans data:`, arguments)
    dataLayer.push(arguments);
  }

  gtag('js', new Date());
  gtag('config', 'AW-10868599597');
}

document.head.appendChild(script)