<template>
  <div class="registerBox">
    <div class="registerLeft">
      <div class="registerBg" style="display: none">
        <img src="@/assets/images/registerBgMobile.png" alt="" />
      </div>
      <div class="logoBox">
        <img src="@/assets/MMCloud.svg" alt="" />
      </div>
      <div class="systemName">MMCloud</div>
      <div class="systemIntro">
        三步完成企业认证，即可享受极速开户服务<br />
        60s自助开户信息填写，最快当天成功开通广告账户，广告费用随时随地自助充值<br />
        Facebook、TikTok、Microsoft、Google等主流海外广告账户一键开户，帮助广告主快速开展海外营销
      </div>
      <div class="guideBox">
        <div class="title">
          <img src="@/assets/iconImg/icon_guide.png" alt="" />
          如何使用MMCloud开启出海之路
        </div>
        <div class="content">
          1.登录MMCloud<br />
          2.完成企业认证<br />
          3.免费自助开户
        </div>
      </div>
    </div>
    <div class="registerRight">
      <div class="content">
        <div class="titleBox">
          <div class="title">欢迎使用MMCloud</div>
          <div class="subTitle">专业的一站式海外广告智能管理系统</div>
        </div>
        <div class="registerTitle">注册</div>
        <div class="registerForm">
          <el-form ref="registerFormRef" :model="registerForm" :rules="registerFormRules" label-width="0px"
            class="login-form">
            <el-form-item prop="phone">
              <el-input placeholder="请输入您的手机号" v-model="registerForm.phone" clearable>
                <template #prefix>
                  <el-icon class="el-input__icon">
                    <img v-if="isMobile" src="@/assets/iconImg/icon_phone.svg" alt="" />
                    <Phone v-else />
                  </el-icon>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="phoneCode">
              <div class="codeBox">
                <el-input v-model="registerForm.phoneCode" placeholder="请输入您的验证码" clearable>
                  <template #prefix>
                    <el-icon class="el-input__icon">
                      <img v-if="isMobile" src="@/assets/iconImg/icon_code.svg" alt="" />
                      <ChatDotRound v-else />
                    </el-icon>
                  </template>
                </el-input>

                <el-button :disabled="!rightPhone" @click="getCode">
                  {{ countDownTime > 0 ? `已发送${countDownTime}s` : '获取验证码' }}
                </el-button>
              </div>
            </el-form-item>
            <el-form-item prop="password">
              <el-input placeholder="请输入您的密码" v-model="registerForm.password" type="password" show-password clearable>
                <template #prefix>
                  <el-icon class="el-input__icon">
                    <img v-if="isMobile" src="@/assets/iconImg/icon_password.svg" alt="" />
                    <Lock v-else />
                  </el-icon>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="password1">
              <el-input placeholder="请再次输入您的密码" v-model="registerForm.password1" type="password" show-password clearable>
                <template #prefix>
                  <el-icon class="el-input__icon">
                    <img v-if="isMobile" src="@/assets/iconImg/icon_password.svg" alt="" />
                    <Lock v-else />
                  </el-icon>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item class="agree">
              <el-row>
                <el-checkbox v-model="isAgree" style="margin-right: 5px"></el-checkbox>
                <div class="agreeBox">
                  <span class="Text">{{ isMobile ? '登录即表示已阅读并同意' : '登陆即表示同意' }}</span>
                  <el-link :href="`${base.url}/service.html`" target="_blank" :underline="false">
                    <span class="brandColor" style="font-size: 14px">《MMCloud用户服务协议》</span>
                  </el-link>
                </div>
              </el-row>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" style="width: 100%" @click="PhoneRegister" :disabled="isAgree ? false : true">注册
              </el-button>
            </el-form-item>
          </el-form>
          <div>
            <router-link to="/login" class="goLogin" target="_blank">
              <span class="brandColor" style="font-size: 14px">使用已有账号登录</span></router-link>
          </div>
        </div>
      </div>
      <div class="copyright">
        <a href="https://beian.miit.gov.cn" target="_blank">
          杭州千凤文化传媒有限公司版权所有Copyright © 2020
          <img src="@/assets/officeIcon.png" alt="" /><a
            href="https://beian.mps.gov.cn/#/query/webSearch?code=33011002017562" rel="noreferrer"
            target="_blank">浙公网安备33011002017562</a>
        </a>
      </div>
    </div>
  </div>

  <!-- 绑定微信号对话框 -->
  <div class="bindWeChat">
    <el-dialog v-model="bindWeChatDialogVisible" :close-on-click-modal="false" :close-on-press-escape="false"
      :show-close="false" title="绑定微信号" width="20%">
      <p class="Text" style="text-align: center">绑定后可微信登陆<br /></p>
      <div style="text-align: center">
        <el-image style="width: 200px; height: 200px" :src="wxUrl" fit="fill"></el-image>
      </div>
    </el-dialog>
  </div>

  <el-dialog custom-class="addWXUser" v-if="'add-wx-user' === dialogType" v-model="showDialog" title="" width="430px"
    @close="closeAndRefresh">
    <img class="server_qrcode" :src="live_code" alt="" />
  </el-dialog>
</template>

<script>
import base from '../request/base'
import google from '@/base/google'

export default {
  name: 'Register',
  data() {
    // 验证手机号的规则
    var checkMobile = (rule, value, cb) => {
      // 验证手机号的正则表达式
      const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|16[0-9]|17[0-9]|18[0-9]|19[0-9]|14[57])[0-9]{8}$/

      if (regMobile.test(value)) {
        return cb()
      }

      cb(new Error('请输入有效的电话号码'))
    }
    return {
      base: base,
      registerToken: '',
      userInfo: '',
      userId: '',
      registerForm: {
        type: 'code',
        phone: '',
        phoneCode: '',
        password: '',
        password1: '',
      },
      registerFormRules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' },
        ],
        phoneCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,12}$/,
            message: '必须包含大小写字母和数字的组合，可以使用特殊字符，长度在 6-12 之间',
            trigger: 'change',
          },
        ],
        password1: [
          { required: true, message: '请再次输入密码', trigger: 'blur' },
          {
            pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,12}$/,
            message: '必须包含大小写字母和数字的组合，可以使用特殊字符，长度在 6-12 之间',
            trigger: 'change',
          },
        ],
      },
      countDownTime: 0,
      isAgree: false,
      bindWeChatDialogVisible: false,
      wxUrl: '',
      wxKey: '',
      wxClock: undefined,
      dialogType: '',
      showDialog: false,
      live_code: '',
      isMobile: false,
    }
  },
  computed: {
    rightPhone() {
      return /^(0|86|17951)?(13[0-9]|15[012356789]|16[0-9]|17[0-9]|18[0-9]|19[0-9]|14[57])[0-9]{8}$/.test(
        this.registerForm.phone
      )
    },
  },
  mounted() {
    if (this.$route.query && this.$route.query.token) {
      localStorage.setItem('qbit-token', this.$route.query.token)
    }
    // 判断移动端将isAgree改为true
    if (window.innerWidth < 768) {
      this.isMobile = true
      this.isAgree = true
    }
  },
  methods: {
    getCode() {
      if (!this.countDownTime) {
        // 启动倒计时
        this.countDownTime = 60
        const clock = window.setInterval(() => {
          this.countDownTime--
          if (this.countDownTime <= 0) {
            clearInterval(clock)
          }
          this.content = this.countDownTime + 's后重新发送'
        }, 1000)
        this.$http.post('login/login', this.registerForm)
      }
    },
    closeAndRefresh() {
      this.dialogType = ''

      if (this.$store.getters.userInfo.crm_id > 0) {
        this.$router.push('/home')
      } else {
        this.$router.push('/users')
      }
    },
    async addWxUser(res) {
      localStorage.removeItem('qbit-token')
      if (!res.user || !res.user.phone || res.user.q_type === 1 || res.user.exc === 'qbit') {
        window.localStorage.setItem('userInfo', JSON.stringify(res.user))
        this.$store.dispatch('EDIT_USERINFO', res.user)
        if (refresh) this.closeAndRefresh()
        return
      }
      window.localStorage.setItem('userInfo', JSON.stringify(res.user))
      this.$store.dispatch('EDIT_USERINFO', res.user)
      this.closeAndRefresh()
    },
    PhoneRegister() {
      this.$refs.registerFormRef.validate(async (vaild) => {
        if (!vaild) return
        const { data: res } = await this.$http.post('login/register', {
          phone: this.registerForm.phone,
          phoneCode: this.registerForm.phoneCode,
          password: this.registerForm.password,
          password1: this.registerForm.password1,
          token: location.search.split('token=').length > 1 ? location.search.split('token=')[1] : '',
          source: this.$route.query && this.$route.query.source ? this.$route.query.source : '',
        })
        if (res.code != 200) return this.$message.error(`${res.msg}`)
        // if (res.user.w_openid == '' || res.user.w_openid == null) {
        //   this.userId = res.user.id
        //   this.wxBindImg()
        //   this.bindWeChatDialogVisible = true
        // } else {
        // this.$message.success('登录成功')
        this.addWxUser(res)
      })
    },
    // 获取绑定微信二维码
    async wxBindImg() {
      const { data: res } = await this.$http.get('login/wxImg')
      this.wxUrl = res.data.url
      this.wxKey = res.data.key
      this.wxBindLogin()
    },
    // 绑定微信登陆
    wxBindLogin() {
      this.wxClock = window.setInterval(async () => {
        const { data: res } = await this.$http.post('login/edit_whchat', {
          titcket: this.wxKey,
          user_id: this.userId,
          type: 3,
        })
        if (res.code == 100) {
          return this.$message.error(`${res.msg}`)
        }
        if (res.code == 300) {
          clearInterval(this.wxClock)
          this.$router.push('/login')
        }
        if (res.code == 200) {
          clearInterval(this.wxClock)
          this.$message.success('绑定成功')
          this.bindWeChatDialogVisible = false
          this.addWxUser(res)
        }
      }, 1000)
    },
  },
}
</script>
<!--<script async src="https://www.googletagmanager.com/gtag/js?id=AW-10868599597"></script> <script> window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-10868599597'); </script>-->

<style lang="less" scoped>
a {
  text-decoration: none;
}

.goLogin {
  display: block;
  text-align: center;
}

.registerBox {
  position: relative;
  height: 100vh;
  min-width: 1200px;
  min-height: 700px;
  display: grid;
  grid-template-columns: 50% 50%;
  background-color: #fff;
}

.registerLeft {
  position: relative;
  background: url(../assets/images/registerBG.png) no-repeat;
  background-size: cover;
  background-position: center;
  padding: 60px;
}

.logoBox {
  width: 263px;
  height: 48px;
  margin-bottom: 40px;
}

.logoBox img {
  width: 100%;
}

.systemName {
  font-size: 24px;
  color: #262626;
  margin-bottom: 24px;
}

.systemIntro {
  font-size: 14px;
  color: #595959;
  line-height: 24px;
}

.guideBox {
  position: absolute;
  left: 60px;
  bottom: 60px;
}

.guideBox .title {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #262626;
  margin-bottom: 8px;
}

.guideBox .title img {
  width: 30px;
  height: 30px;
  margin-right: 8px;
}

.guideBox .content {
  font-size: 14px;
  color: #595959;
  line-height: 24px;
}

.registerRight {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.registerRight .content {
  margin: 0 auto;
}

.titleBox {
  margin-bottom: 40px;
}

.titleBox .title {
  font-size: 36px;
  font-weight: 700;
  color: #262626;
  margin-bottom: 16px;
}

.titleBox .subTitle {
  font-size: 18px;
  color: #595959;
}

.registerTitle {
  font-size: 24px;
  color: #262626;
  text-align: center;
  margin-bottom: 32px;
}

.registerForm {
  width: 440px;
}

.codeBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.codeBox .el-input {
  width: 298px;
}

.codeBox .el-button {
  width: 134px;
}

.copyright {
  position: absolute;
  bottom: 40px;
  font-size: 12px;
}

.copyright a {
  display: flex;
  align-items: center;
  color: #595959;
}

.copyright a img {
  width: 14px;
  height: 14px;
}

.el-input__icon {
  height: 100% !important;
  display: flex;
  align-items: center;
  margin-left: 6px;
}

/* 移动端自适应样式 */
@media screen and (max-width: 768px) {
  .registerBox {
    width: 100vw !important;
    display: block !important;
    min-width: 0 !important;
    min-height: 0 !important;

    .registerLeft {
      position: relative;
      height: 100vh !important;
      background: none !important;
      background-size: 30% auto;
      padding: 0 !important;
      z-index: 1;

      .logoBox {
        width: 120px !important;
        height: 22px !important;
        position: absolute;
        top: 52px;
        left: 20px;
      }

      .registerBg {
        display: block !important;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 33%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .systemName {
        display: none !important;
      }

      .systemIntro {
        display: none !important;
      }

      .guideBox {
        display: none !important;
      }
    }

    .registerRight {
      position: absolute !important;
      left: 0;
      bottom: 0;
      height: 71vh;
      width: 100vw !important;
      z-index: 99;
      border-radius: 12px 12px 0 0;
      background-color: #fff;

      .content {
        width: 100%;
        height: 100% !important;
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .registerTitle {
          display: none !important;
        }

        .titleBox {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 0 !important;
          margin-bottom: 20px !important;

          .title {
            width: 239px;
            height: 28px;
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 28px;
            margin-bottom: 8px !important;
          }

          .subTitle {
            width: 192px;
            height: 20px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 20px;
          }
        }

        .registerForm {
          width: 315px !important;
          flex: 1;

          .login-form {
            height: 100%;
            position: relative;

            .el-input__icon {
              margin-left: 10px;
            }

            .el-input /deep/ .el-input__inner {
              padding-left: 40px;
            }

            .codeBox {
              .el-input {
                width: 200px !important;
              }

              .el-button {
                width: 102px !important;
                display: flex;
                height: 48px;
                justify-content: center;
                align-items: center;
                color: #999999;
              }
            }

            .el-checkbox {
              display: none;
            }

            .agree {
              position: absolute;
              bottom: 4%;
              width: 100%;
              margin-bottom: 0px;

              .agreeBox {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .Text {
                  font-size: 12px;
                  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                  font-weight: 400;
                  color: #666666;
                  line-height: 20px;
                }

                .el-link {
                  span {
                    font-size: 12px !important;
                  }
                }
              }
            }
          }

          .el-input /deep/ .el-input__inner {
            height: 48px;
          }

          .el-input /deep/ .el-input__inner::placeholder {
            color: #999999;
          }

          .goLogin {
            display: none;
          }
        }
      }

      .copyright {
        display: none !important;
      }
    }
  }

  .bindWeChat /deep/ .el-dialog {
    width: 100% !important;
  }
}
</style>
<style>
.addWXUser {
  height: 560px !important;
  background: url('../assets/images/popBG.png') no-repeat;
  background-size: 100%;
}

.addWXUser .el-dialog__header {
  padding: 0 !important;
  border: 0 !important;
}

.addWXUser .el-dialog__body {
  padding: 0 !important;
}

.server_qrcode {
  width: 268px;
  height: 268px;
  position: absolute;
  top: 211px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
